.carousel-wrapper {
    display: flex;
    justify-content: center; /* Center the carousel horizontally */
    align-items: center;
    width: 100%; /* Full width of parent */
    overflow: hidden; /* Prevent horizontal scrolling */
  
  }
  
  .carousel-container {
    position: relative;
    width: 100%; /* Full width of the parent container */
    max-width: 1200px; /* Optional: Set a maximum width */
    height: auto; /* Height will be adjusted by the aspect ratio */
    aspect-ratio: 16 / 9; /* Set aspect ratio (e.g., 16:9) */
    overflow: hidden; /* Hide overflow to prevent scrollbar */
    border-radius: 16px;
    /* border: 1px solid red; */
  }
  
  .carousel-slide {
    display: flex;
    justify-content: center; /* Center align images in slides */
    align-items: center;
    border-radius: 16;
    overflow: hidden;
  }
  
  .carousel-slide img {
    width: 100%; /* Ensure image covers the width of the slide */
    height: auto; /* Adjust height to maintain aspect ratio */
    object-fit: cover; /* Adjust image fitting */
    overflow: hidden;
    border-radius: 16;
  }
  
  .slick-dots {
    bottom: 10px; /* Position dots below the carousel */
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .carousel-container {
      aspect-ratio: 4 / 3; /* Adjust aspect ratio for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .carousel-container {
      aspect-ratio: 16 / 9; /* Square aspect ratio for very small screens */
    }
  }
  