  .background-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content {
    text-align: center;
    color: white; /* White text color */
  }
  
  .text {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .button {
    background-color: white; /* White background for button */
    color: black; /* Black text color for button */
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #f0f0f0; /* Slightly darker white on hover */
  }
  
  



