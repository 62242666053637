/* CustomModal.css */
.custom-modal .modal-dialog {
    max-width: 80%; /* Adjust the width as needed */
    width: 90%; /* Ensure it does not exceed the container width */
  }
  
  .custom-modal .modal-content {
    height: 80vh; /* Adjust height if necessary */
    border-radius: 10px; /* Optional: Rounded corners */
  }
  