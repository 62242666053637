/* Container styling */
.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  overflow: auto; /* Allow scrolling */
  border-radius: 16px;
  border: 1px solid #D9D9D9;
}

/* Table styling */
.responsive-table {
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
}

/* Remove default borders */
.responsive-table,
.responsive-table th,
.responsive-table td {
  border: none;
}

/* Ensure text alignment and wrapping */
.responsive-table th,
.responsive-table td {
  text-align: left; /* Left-align text */
  white-space: normal; /* Allow text to wrap */
  word-break: break-word; /* Break long words to prevent overflow */
  overflow-wrap: break-word; /* Break long words */
  padding: 10px; /* Add some padding */
}

/* Specific styling for table headings */
.responsive-table th {
  text-overflow: clip; /* Prevent ellipsis */
  max-width: none; /* Remove max-width constraint */
  overflow: visible; /* Ensure overflow is visible */
  /* Optional: Set a minimum width if needed */
  min-width: 100px; /* Adjust as needed */
}

/* Flexbox styling for the last column cells */
.last-column-cell {
  display: flex;
  align-items: center;
  gap: var(--spacing-between-texts);
}

/* Line-through text styling */
.line-through-text {
  text-decoration: line-through;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .responsive-table {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .table-container {
    padding: 10px; /* Adjust padding for smaller screens */
  }
}

@media (max-width: 480px) {
  .responsive-table {
    font-size: 12px; /* Further adjustment for very small screens */
  }

  .table-container {
    padding: 5px; /* Further adjustment for very small screens */
  }
}


@media (max-width: 480px) {
    .tableComp {
        width: 100% !important;
        margin: 2px !important;
        padding: 0 !important;
      
    }
    .tablecel {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
  
    .table-container {
      padding: 5px; /* Further adjustment for very small screens */
    }
  }
