input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none; /* Modern browsers */
}

/* Optional: You may also want to remove the margin that browsers apply to number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

